import { render, staticRenderFns } from "./detailBilling.vue?vue&type=template&id=10096278&scoped=true"
import script from "./detailBilling.vue?vue&type=script&lang=js"
export * from "./detailBilling.vue?vue&type=script&lang=js"
import style0 from "./detailBilling.vue?vue&type=style&index=0&id=10096278&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "10096278",
  null
  
)

export default component.exports