<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import useNetw from "@useNetw";
import Swal from "sweetalert2";
import Multiselect from "vue-multiselect";
// import receiptroComponent from "@/components/tabReceiptRoComponent";
import DatePicker from "vue2-datepicker";

// import { tableData } from "./dataAdvancedtable";
export default {
  page: {
    title: "แก้ไขข้อมูลวางบิล",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    Multiselect,
    // receiptroComponent,
    DatePicker,
  },
  data() {
    return {
      rowsRopart: [],
      optionTypeDc: [
        {
          name: "บาท",
          type: "bath",
        },
        {
          name: "เปอรเซ็น",
          type: "per",
        },
      ],
      optionsRoWhtPer: [
        {
          name: "ไม่ระบุ",
          id: 0,
        },
        {
          name: "3%",
          id: 3,
        },
      ],
      wages: {
        name: "ไม่ระบุ",
      },
      form: false,
      loading: false,
      wageItem: {
        wageId: "",
        selectWage: {},
        nameTh: "",
        nameEn: "",
        wageCode: "",
        pricePerOne: 0,
        amount: 0,
        totalPrice: 0,
        dcPer: 0,
        dcPrice: 0,
        netPrice: 0,
        dcMethod: {
          name: "บาท",
          type: "bath",
        },
      },
      part: [],
      rowsCreate: [],
      userBranchId: "",
      filterCreate: {
        branch: "",
        licensePlate: "",
        vin: "",
      },
      currentPage: 1,
      perPage: 10,
      filter: {
        userBranchId: "",
      },
      totalPage: "",
      totalRows: 1,
      totalRecord: 0,
      pageOptions: [10, 25, 50, 100],
      selectMode: "multi",
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      optionBranch: [],
      dismissSecs: 3,
      dismissCountDown: 0,
      resalert: "",
      wpSelect: {
        totalPrice: 0,
        vatPer: appConfig.vat,
        vatPrice: 0,
        netPrice: 0,
        dcPrice: 0,
        dcPer: 0,
        grandTotalPrice: 0,
      },
      title: "แก้ไขข้อมูลวางบิล",
      items: [
        {
          text: "การเงิน",
          active: true,
        },
        {
          text: "วางบิล",
          active: false,
          href: "/billings",
        },
        {
          text: "แก้ไขข้อมูลวางบิล",
          active: true,
        },
      ],
      selected: "",
      branchMaster: [],
      rowsRo: [],
      totalRecords: 0,
      isHidden: false,
      branch: "",
      type: "",
      roId: "",

      billingId: this.$route.params.billingId,
      payment: [],
      userBranch: "",
      rows: [],
      overlayFlag: false,

      billingCode: "",
      licensePlate: "",
      customerNameTh: "",
      customerFamilyNameTh: "",
      branchName: "",
      cusName: "",
      rowsCustomer: [],
      rowsVehicle: [],
      vehicle: "",
      billingDate: "",
      credit: "",
      dueDate: "",
      note: "",
      rowsDetail: [],

      totalPrice: "",
      dcPer: "",
      dcPrice: "",
      netPrice: "",
      vatPer: "",
      vatPrice: "",
      grandTotalPrice: "",
      fieldsCreate: [
        {
          key: "index",
          label: "ลำดับ",
          sortable: false,
        },
        {
          key: "inv_code",
          sortable: true,
          label: "รหัสใบแจ้งหนี้",
        },
        {
          key: "inv_date",
          sortable: true,
          label: "วันที่สร้าง",
        },
        {
          key: "grand_total_price",
          sortable: true,
          label: "ยอดรวม",
        },
      ],
    };
  },
  computed: {},
  mounted() {
    const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
    const arrayBranch = [];
    user.userAccessBranch.forEach((item) => {
      arrayBranch.push(item.branchId);
    });
    this.branchMaster = user.userAccessBranch;
    this.userBranchId = user.branchId;
    this.userBranch = arrayBranch;
    this.totalRows = this.rows.total;
    this.getData();
    this.getDataCustomer();
    // this.getDataCreate();
    this.getDataBillingDetail();
    //     const date = new Date();
    // date.setDate(date.getDate() + 7);

    // var d = new Date(),
    //   month = "" + (d.getMonth() + 1),
    //   day = "" + d.getDate(),
    //   year = d.getFullYear();
    // if (month.length < 2) month = "0" + month;
    // if (day.length < 2) day = "0" + day;
    // this.date = year + "-" + month + "-" + day;

    // const date = new Date();
    // d.setDate(d.getDate() + 7);
    // this.getDataWagePart();
  },
  created() {},
  methods: {
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    showAlert(response) {
      this.dismissCountDown = this.dismissSecs;
      this.resalert = response;
    },
    customLabel({ nameTh, familyNameTh }) {
      return `${nameTh}  ${familyNameTh}`;
    },
    calDeleteWp(billing) {
      this.wpSelect.totalPrice =
        parseFloat(this.totalPrice) - parseFloat(billing.netPrice);
      this.wpSelect.dcPrice =
        (parseFloat(this.wpSelect.totalPrice) / 100) * this.dcPer;
      this.wpSelect.netPrice =
        parseFloat(this.wpSelect.totalPrice) -
        parseFloat(this.wpSelect.dcPrice);
      this.wpSelect.vatPrice =
        (parseFloat(this.wpSelect.netPrice) / 100) * this.vatPer;
      this.wpSelect.grandTotalPrice =
        parseFloat(this.wpSelect.netPrice) + this.wpSelect.vatPrice;
    },
    calUpdateWp() {
      let pricePerOne = 0;
      let netPrices = 0;
      // let dcPer = 0;
      // let totalPrice = 0;
      this.rowsWp.forEach((item) => {
        pricePerOne += parseFloat(item.netPrice);
        netPrices += parseFloat(item.totalPrice);
        // dcPer += parseFloat(item.dcPer);
        // totalPrice += parseFloat(item.totalPrice) * item.amount;
      });
      this.wpSelect.netPrice = netPrices.toFixed(2);
      this.wpSelect.totalPrice = pricePerOne.toFixed(2);
      this.wpSelect.vatPrice = (this.wpSelect.netPrice / 100) * this.vatPer;
      this.wpSelect.grandTotalPrice = netPrices + this.wpSelect.vatPrice;

      this.totalPrice = parseFloat(this.wpSelect.totalPrice);
      this.netPrice = parseFloat(this.totalPrice) - parseFloat(this.dcPrice);
      this.grandTotalPrice =
        parseFloat(this.netPrice) + parseFloat(this.vatPrice);
      // this.dcPer = (this.totalPrice / 100) * this.dcPer;
      this.dcPrice = (this.totalPrice / 100) * this.dcPer;
    },
    addBilling: function() {
      const arrInvId = [];
      this.selected.forEach((item) => {
        arrInvId.push(item.inv_id);
      });
      useNetw
        .post("api/billing/detail/store", {
          billingId: this.billingId,
          invId: arrInvId,
        })
        .then((response) => {
          // Swal.fire(
          //   appConfig.swal.title.postSuccess,
          //   JSON.stringify(response.data.message),
          //   appConfig.swal.type.success
          // );
          this.showAlert(response.data.message);
          this.$refs["modalCreateBilling"].hide();
          this.getData();
          this.getDataBillingDetail();
          this.getDataCreate();

          //   this.getDataWagePart();
          //   this.getData();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {});
    },
    onRowSelected(rowRo) {
      this.selected = rowRo;
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
    },
    getData: function() {
      this.overlayFlag = true;
      useNetw
        .get("api/billing/show", {
          params: {
            billingId: this.billingId,
          },
        })
        .then((response) => {
          this.rows = response.data.data;
          this.billingCode = response.data.data.billingCode;
          this.billingDate = response.data.data.billingDate;
          this.dueDate = response.data.data.dueDate;
          this.totalPrice = response.data.data.totalPrice;
          this.dcPer = response.data.data.dcPer;
          this.dcPrice = response.data.data.dcPrice;
          this.netPrice = response.data.data.netPrice;
          this.vatPer = response.data.data.vatPer;
          this.vatPrice = response.data.data.vatPrice;
          this.grandTotalPrice = response.data.data.grandTotalPrice;
          this.note = response.data.data.note;
          this.cusName = {
            cusId: response.data.data.cusId,
            nameTh: response.data.data.customerNameTh,
            familyNameTh: response.data.data.customerFamilyNameTh,
          };
          this.branchName = {
            nameTh: response.data.data.branchName,
            branchId: response.data.data.branchId,
          };
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    updateBilling: function() {
      this.overlayFlag = true;
      useNetw
        .put("api/billing/update", {
          billingId: this.billingId,
          cusId: this.cusName.cusId,
          billingDate: this.billingDate,
          dueDate: this.dueDate,
          note: this.note,
          totalPrice: this.totalPrice,
          dcPer: this.dcPer,
          dcPrice: this.dcPrice,
          netPrice: this.netPrice,
          vatPer: this.vatPer,
          vatPrice: this.vatPrice,
          grandTotalPrice: this.grandTotalPrice,
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.postSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.message +
                "<br>" +
                err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    getDataCustomer: function() {
      this.loading = true;
      useNetw
        .get("api/customer", {
          params: {
            page: 1,
            perPage: 100,
            "branchId[]": this.userBranchId,
            nameTh: this.cusName.nameTh,
          },
        })
        .then((response) => {
          this.rowsCustomer = response.data.data;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.loading = false;
        });
    },
    getDataCreate: function() {
      this.loading = true;
      useNetw
        .get("api/billing/invoices", {
          params: {
            page: this.currentPage,
            perPage: this.perPage,
            branchId:
              this.filterCreate.branch != null
                ? this.filterCreate.branch.branchId
                : this.userBranchId,
          },
        })
        .then((response) => {
          this.rowsCreate = response.data.data;
          this.rowsCreate.total = response.data.from;
          this.totalRecord = response.data.total;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.loading = false;
        });
    },
    getDataBillingDetail: function() {
      this.loading = true;
      useNetw
        .get("api/billing/detail", {
          params: {
            billingId: this.billingId,
          },
        })
        .then((response) => {
          this.rowsDetail = response.data.data;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.loading = false;
        });
    },
    handleChangePage(page) {
      this.currentPage = page;
      this.getDataCreate();
    },
    handlePageChange(active) {
      this.perPage = active;
      this.currentPage = 1;
      this.getDataCreate();
    },
    alertDelete(billing, index) {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger ms-2",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: "ต้องการลบรายการหรือไม่ !!?",
          // text: "ต้องการออกใบกำกับภาษีหรือไม่ !!",
          icon: "warning",
          confirmButtonText: "OK",
          cancelButtonText: "Cancel!",
          showCancelButton: true,
        })
        .then((result) => {
          if (result.value) {
            this.calDeleteWp(billing);
            this.deleteBilling(billing, index);
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            swalWithBootstrapButtons.fire(
              "Cancelled",
              "ยกเลิกเรียบร้อยแล้ว",
              "error"
            );
          }
        });
    },
    deleteBilling: function(rowsIvn) {
      this.loading = true;
      useNetw
        .delete("api/billing/detail/delete", {
          data: {
            billdId: rowsIvn.billdId,
          },
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.postSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.getDataBillingDetail();
          this.getData();
          this.getDataCreate();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.loading = false;
        });
    },
  },
  middleware: "authentication",
};
</script>

<style lang="scss" scoped>
@media only screen and (min-width: 500px) {
  .alert-fixed {
    position: fixed;
    top: 50px;
    left: 65%;
    width: 30%;
    z-index: 9999;
    border-radius: 0px;
  }
}

.con-nav-footer {
  padding-left: 20rem;
}
.part {
  width: 240px;
}
.wage {
  width: 200px;
}
.number {
  width: 140px;
  text-align: right;
}
.Dc {
  width: 100px;
}
.action {
  width: 40px;
}
.numberP {
  width: 140px;
  text-align: right;
}
.price {
  width: 170px;
  text-align: right;
}
</style>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <b-alert
          class="alert-fixed fixed-top rounded-1 m-4 bg-light alert-front"
          :show="dismissCountDown"
          dismissible
          fade
          @dismissed="dismissCountDown = 0"
          @dismiss-count-down="countDownChanged"
        >
          <p class="float-end">{{ dismissCountDown }} วินาที...</p>
          <p>แจ้งเตือน</p>
          <hr />
          <p>{{ resalert }}</p>
          <!-- <b-progress
        variant="warning"
        :max="dismissSecs"
        :value="dismissCountDown"
        height="4px"
      ></b-progress> -->
        </b-alert>
        <b-overlay
          :show="overlayFlag"
          :spinner-variant="this.$overlayVariant"
          :spinner-type="this.$overlayType"
          :rounded="this.$overlayRounded"
        >
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-7">
                  <div class="row">
                    <div class="col-7 col-sm-6 col-md-4">
                      <div class="mb-3">
                        <code> * </code>ชื่อลูกค้า:
                        <multiselect
                          v-model="cusName"
                          label="nameTh"
                          :options="rowsCustomer"
                          :custom-label="customLabel"
                          :show-labels="false"
                          open-direction="bottom"
                          placeholder=""
                        >
                          <span slot="noResult">ไม่พบข้อมูล</span>
                        </multiselect>
                      </div>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                      <div class="mb-3">
                        <code> * </code>เลขที่ใบวางบิล:
                        <b-form-input
                          v-model="billingCode"
                          disabled
                          class="form-control"
                        ></b-form-input>
                      </div>
                    </div>
                    <!-- <div class="col-6 col-sm-6 col-md-4">
                      <div class="mb-3">
                        <code> * </code>ป้ายทะเบียน:
                        <multiselect
                          v-model="vehicle"
                          label="licensePlate"
                          :options="rowsVehicle"
                          :show-labels="false"
                          open-direction="bottom"
                          placeholder=""
                        >
                          <span slot="noResult">ไม่พบข้อมูล</span>
                        </multiselect>
                      </div>
                    </div> -->
                    <div class="col-6 col-sm-6 col-md-4">
                      <div class="mb-3">
                        <code> * </code>วันที่ออกใบชำระเงิน:
                        <date-picker
                          v-model="billingDate"
                          format="YYYY-MM-DD"
                          value-type="format"
                        ></date-picker>
                      </div>
                    </div>
                    <!-- <div class="col-3 col-sm-6 col-md-2">
                      <div class="mb-3">
                        เครดิต:
                        <b-form-input
                          v-model="credit"
                          class="form-control"
                          disabled
                        ></b-form-input>
                      </div>
                    </div> -->
                    <div class="col-9 col-sm-6 col-md-4">
                      <div class="mb-3">
                        <code> * </code>วันครบกำหนดชำระ:
                        <date-picker
                          v-model="dueDate"
                          format="YYYY-MM-DD"
                          value-type="format"
                        ></date-picker>
                      </div>
                    </div>
                    <div class="col-12 col-sm-6 col-md-6">
                      <div class="mb-3">
                        หมายเหตุ:
                        <b-form-textarea
                          v-model="note"
                          rows="3"
                          max-rows="6"
                        ></b-form-textarea>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-sm-12 col-md-5">
                  <div class="row mb-4">
                    <div class="col-12 d-flex justify-content-end">
                      <div
                        class="col-12 col-sm-12 col-md-12 bg-white m-0 text-end"
                        style="border-radius:10px;
                            margin:auto;
                            justify "
                      >
                        <div class="row align-items-center m-0">
                          <div class="col-12">
                            <div class="row mt-2">
                              <div class="col-4 col-sm-5 col-md-4">
                                ราคารวม :
                              </div>
                              <div class="col-6">
                                <b-form-input
                                  v-model="totalPrice"
                                  disabled
                                  class="form-control text-end"
                                ></b-form-input>
                              </div>
                              <div class="col-2">
                                บาท
                              </div>
                            </div>
                            <div class="row mt-2">
                              <div class="col-4 col-sm-5 col-md-4">
                                ส่วนลด (%):
                              </div>
                              <div class="col-6">
                                <b-form-input
                                  v-model="dcPer"
                                  class="form-control text-end"
                                  disabled
                                ></b-form-input>
                              </div>
                              <div class="col-2">
                                (%)
                              </div>
                            </div>
                            <div class="row mt-2">
                              <div class="col-4 col-sm-5 col-md-4">
                                ส่วนลด (บาท) :
                              </div>
                              <div class="col-6">
                                <b-form-input
                                  v-model="dcPrice"
                                  class="form-control text-end"
                                  disabled
                                ></b-form-input>
                              </div>
                              <div class="col-2">
                                บาท
                              </div>
                            </div>
                            <div class="row mt-2">
                              <div class="col-4 col-sm-5 col-md-4">
                                สุทธิ :
                              </div>
                              <div class="col-6">
                                <b-form-input
                                  v-model="netPrice"
                                  disabled
                                  class="form-control text-end"
                                ></b-form-input>
                              </div>
                              <div class="col-2">
                                บาท
                              </div>
                            </div>
                            <div class="row mt-2">
                              <div class="col-4 col-sm-5 col-md-4">
                                ภาษี (%):
                              </div>
                              <div class="col-6">
                                <b-form-input
                                  v-model="vatPer"
                                  disabled
                                  class="form-control text-end"
                                ></b-form-input>
                              </div>
                              <div class="col-2">
                                (%)
                              </div>
                            </div>
                            <div class="row mt-2">
                              <div class="col-4 col-sm-5 col-md-4">
                                ภาษี (บาท):
                              </div>
                              <div class="col-6">
                                <b-form-input
                                  v-model="vatPrice"
                                  disabled
                                  class="form-control text-end"
                                ></b-form-input>
                              </div>
                              <div class="col-2">
                                บาท
                              </div>
                            </div>
                            <div class="row mt-2 mb-2">
                              <div class="col-4 col-sm-5 col-md-4">
                                มูลค่ารวม :
                              </div>
                              <div class="col-6 font-size-24">
                                <b-form-input
                                  v-model="grandTotalPrice"
                                  disabled
                                  class="form-control text-end"
                                ></b-form-input>
                              </div>
                              <div class="col-2">
                                บาท
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-12 col-sm-12 col-md-12">
                  <b-button
                    class="btn float-end m-1"
                    variant="info"
                    type="submit"
                    @click="updateBilling"
                  >
                    <!-- <i class="uil-search-alt"></i> -->
                    บันทึก
                  </b-button>
                  <!-- <b-button
                    class="btn float-end m-1"
                    variant="primary"
                    type="submit"
                    v-b-modal.modalCreateBilling
                  >
                    สร้างใบวางบิล
                  </b-button> -->
                </div>
              </div>

              <div class="row">
                <div class="card mt-4">
                  <div class="p-4 border-top">
                    <form class="needs-validation">
                      <div class="row">
                        <div class="col-12 col-sm-12 col-md-6">
                          <strong class="font-size-18">รายการใบแจ้งหนี้</strong>
                        </div>
                        <div class="col-12 col-sm-12 col-md-6 mb-2">
                          <b-button
                            v-b-modal.modalCreateBilling
                            type="button"
                            class="btn btn-success float-end"
                          >
                            <i class="mdi mdi-plus"></i>
                            สร้างใบวางบิล
                          </b-button>
                        </div>
                      </div>

                      <div class="table-responsive">
                        <table class="table">
                          <thead class="thead-light">
                            <tr>
                              <th>#</th>
                              <th>รหัสใบแจ้งหนี้</th>
                              <th>มูลค่า</th>
                              <th>ส่วนลด</th>
                              <th>ภาษี</th>
                              <th>สุทธิ</th>
                              <th>รวม</th>
                              <!-- <th>Action</th> -->
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(wageItem, index) in rowsDetail"
                              :key="index"
                            >
                              <td>{{ index + 1 }}</td>
                              <td scope="row">
                                <input
                                  min="0"
                                  v-model="wageItem.invCode"
                                  disabled
                                  class="form-control"
                                />
                              </td>
                              <td class="number">
                                <input
                                  min="0"
                                  disabled
                                  v-model="wageItem.totalPrice"
                                  class="form-control text-end"
                                />
                              </td>
                              <td class="Dc">
                                <input
                                  min="0"
                                  v-model="wageItem.dcPer"
                                  disabled
                                  class="form-control text-end"
                                  append=".00"
                                />
                                <!-- <code
                                  >ได้รับส่วนลด :
                                  <strong>{{ wageItem.dcPrice }}</strong></code
                                > -->
                              </td>
                              <td class="Dc">
                                <input
                                  min="0"
                                  v-model="wageItem.vatPrice"
                                  disabled
                                  class="form-control text-end"
                                />
                              </td>
                              <td class="number">
                                <input
                                  min="0"
                                  disabled
                                  v-model="wageItem.netPrice"
                                  class="form-control text-end"
                                />
                              </td>
                              <td class="number">
                                <input
                                  min="0"
                                  v-model="wageItem.grandTotalPrice"
                                  disabled
                                  class="form-control text-end"
                                />
                              </td>
                              <td>
                                <ul class="list-inline mb-0 action">
                                  <li class="list-inline-item">
                                    <a
                                      class="px-2 text-danger"
                                      v-b-tooltip.hover
                                      title="Delete"
                                      @click="alertDelete(wageItem, index)"
                                    >
                                      <i
                                        class="uil uil-trash-alt font-size-18"
                                      ></i>
                                    </a>
                                  </li>
                                </ul>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-overlay>
      </div>
    </div>

    <b-modal
      ref="modalCreateBilling"
      id="modalCreateBilling"
      title="ค้นหารายการวางบิล"
      hide-footer
      centered
      size="xl"
    >
      <div class="card-body">
        <div class="row">
          <div class="col-12 col-sm-12 col-md-12 align-items-center">
            <div class="row align-items-center">
              <div class="col-12 col-sm-6 col-md-4">
                <div class="mb-3">
                  สาขา:
                  <multiselect
                    v-model="filterCreate.branch"
                    label="nameTh"
                    type="search"
                    :options="branchMaster"
                    :show-labels="false"
                    open-direction="bottom"
                    placeholder=""
                    @input="getDataCreate"
                  >
                    <span slot="noResult">ไม่พบข้อมูล</span>
                  </multiselect>
                </div>
              </div>
              <div class="col-12 col-sm-6 col-md-4">
                <div class="mb-3">
                  เลขใบแจ้งหนี้:
                  <b-form-input
                    v-model="filterCreate.licensePlate"
                    type="search"
                    class="form-control"
                  ></b-form-input>
                </div>
              </div>
              <div class="col-12 col-sm-6 col-md-4">
                <div class="mb-3">
                  ชื่อลูกค้า:
                  <b-form-input
                    v-model="filterCreate.vin"
                    type="search"
                    class="form-control"
                  ></b-form-input>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-sm-12 col-md-12">
            <b-button class="btn float-end" variant="info" type="submit">
              <i class="uil-search-alt"></i>
              ค้นหา
            </b-button>
          </div>
        </div>
        <b-skeleton-wrapper :loading="loading" v-if="this.totalRecord > 0">
          <template #loading>
            <b-skeleton-table
              :rows="5"
              :columns="6"
              :table-props="{ bordered: false, striped: true }"
              animation="throb"
            ></b-skeleton-table>
          </template>
          <div class="row mt-2">
            <div class="col-sm-12 col-md-12">
              <div class="row align-items-center">
                <div class="col-sm-5 col-md-3">
                  <div id="tickets-table_length" class="dataTables_length">
                    <label class="d-inline-block align-items-center">
                      แสดงผล
                      <b-form-select
                        v-model="perPage"
                        size="sm"
                        :options="pageOptions"
                      ></b-form-select
                      >&nbsp; รายการ
                    </label>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6"></div>
              </div>
            </div>
          </div>
          <div class="table-responsive mb-0">
            <b-table
              :items="rowsCreate"
              :fields="fieldsCreate"
              responsive="true"
              :per-page="perPage"
              :current-page="1"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :filter="filter"
              :filter-included-fields="filterOn"
              ref="selectableTable"
              selectable
              :select-mode="selectMode"
              @row-selected="onRowSelected"
            >
              <template #cell(index)="rowsCreate">
                {{ rowsCreate.index + 1 }}
              </template>
            </b-table>
          </div>

          <div class="row mb-3">
            <div class="col">
              <b-button
                class="btn float-end"
                variant="primary"
                type="submit"
                @click="addBilling()"
              >
                ยืนยันรายการที่เลือก
              </b-button>
            </div>
          </div>

          <div class="row">
            <div class="col">
              หน้า {{ currentPage }} จาก {{ this.rowsCreate.total }} ทั้งหมด
              {{ this.totalRecord }} รายการ
            </div>

            <div class="col">
              <div class="dataTables_paginate paging_simple_numbers float-end">
                <ul class="pagination pagination-rounded mb-0">
                  <!-- pagination -->
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="totalRecord"
                    :per-page="perPage"
                    @change="handleChangePage"
                  ></b-pagination>
                </ul>
              </div>
            </div>
            <br />
            <hr />
            <br />
          </div>
        </b-skeleton-wrapper>
        <h5 v-if="this.totalRecord === 0" class="text-center">
          ไม่มีรายการใบแจ้งหนี้
        </h5>
      </div>
    </b-modal>
  </Layout>
</template>
